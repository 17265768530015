import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class extends ApplicationController {

  connect() {
    super.connect()
    this.param = new Param(this.getBaseUrl());
  }

  add(event) {
    let $target = $(event.currentTarget);
    let eventId = $target.data('event-id');

    this.stimulate('Appointment#add', eventId).then(payload => {
      reinitializeForms($("#learn_units_form"))
      this.reset_csrf_token();
    })
  }

  ///
  // calls the AppointmentReflex to add a new appointment to the form in the learnable modal
  ///
  addAppointmentToLearnable(event) {
    $("#modal_target > .modal").modal('dispose')
    this.stimulate('Appointment#add_appointment_to_learnable', event.currentTarget).then(payload => {
      reinitializeForms($("#modal_target"))
      $("#modal_target > .modal").modal('show')
    })
  }

  ///
  // toggles show_all/show_from_interest on participation screen
  ///
  switchScope(event) {
    let $target = $(event.currentTarget)
    let isFiltered = $target.prop("checked");
    this.param.updateParams("filtered", isFiltered, false);
    this.stimulate("searchReflex#filter");
  }


}