import ApplicationController from '../../../javascript/controllers/application_controller'
import { newInstance, EVENT_CONNECTION_MOVED, EVENT_CONNECTION_DETACHED, EVENT_CONNECTION, FALSE } from "@jsplumb/browser-ui"

export default class extends ApplicationController {

  // static targets = [ "sortableTree"]
  // static values = {
  //   init: String
  // }

  initialize() {
    super.initialize()
    this.endpointTypes = {
        "source": {
          cssClass: "source-endpoint",
          paintStyle: { fill: "#39ac73" }
        },
        "target": {
          cssClass: "connected-target-endpoint",
          paintStyle: { fill: "#6666ff" }
        },
        "possible-target": {
          cssClass: "unconnected-target-endpoint",
          paintStyle: { fill: "#DDD" }
        }
      }
  }

  connect() {
    super.connect();
    const self = this

    const $skillContainer = $("#skill_set_part")
    
    $skillContainer.find(".row.skill-row").each(function (index) {
      const plumber = newInstance({
        container: this
      })
      plumber.setSuspendDrawing(true);

      plumber.registerEndpointTypes(self.endpointTypes);
      plumber.importDefaults({
        maxConnections: 1,
        reattachConnections: false,
        deleteEndpointsOnDetach: false,
        endpoint: {
          type: "Dot",
          options: { radius: 8 }
        },
        connector: {
          type: "Straight",
          options: { gap: 10 }
        }

      });

      // const ssid = `${$(this).data("sss-id")}`
      const sources = $(this).find('.skill-level .source')
      const connectedSource = sources.filter(".connected")
      if (connectedSource.length) {
        const target = $(this).find(".target").first()[0]
        const sourceEndpoint = plumber.addEndpoint(connectedSource[0], {
          anchor: "Right",
          source: true,
          target: true,
          type: 'source'
        })
        const targetEndpoint = plumber.addEndpoint(target, {
          anchor: "Left",
          target: true,
          type: 'target'
        })
        const connection = plumber.connect({
          source: sourceEndpoint,
          target: targetEndpoint,
        })

      }
      const connectableSource = sources.filter(".connectable")
      if (connectableSource.length) {
        plumber.addEndpoint(connectableSource[0], {
          anchor: "Right",
          source: true,
          target: true,
          type: 'source'
        })
      }
      const connectableTargets = $(this).find(".skill-level .possible-target")
      connectableTargets.each(function (index) {
        plumber.addEndpoint(this, {
          anchor: "Left",
          target: true,
          type: "possible-target",
        })
      })

      plumber.setSuspendDrawing(false, true);

      plumber.bind(EVENT_CONNECTION_MOVED, (p) => { 
        // console.log("## Connection Moved ##")
        // const sreid = p.newEndpoint.element.dataset.sreid
        // const level = p.newEndpoint.element.dataset.level
        // console.log("level: ", level)
        // console.log("sreid: ", sreid)
        // console.log("p: ", p)
        p.originalEndpoint.setType("possible-target")
      }) 

      plumber.bind(EVENT_CONNECTION_DETACHED, (p) => { 
        // console.log("## Connection Detached ##")
        const sreid = p.source.dataset.sreid
        const level = p.source.dataset.level
        // console.log(p)
        // console.log("level: ", level)
        // console.log("sreid: ", sreid)
        // console.log(p.target)
        // console.log(p.target.dataset.level)
        self.stimulate('Skill#adapt_target_rating', sreid, level).then(() => {
          p.targetEndpoint.setType("possible-target")
        })
      }) 

      plumber.bind(EVENT_CONNECTION, (p) => { 
        // console.log("## Connection Created ##")
        const sreid = p.source.dataset.sreid
        const level = p.target.dataset.level
        // console.log(p)
        // console.log(p.source.dataset.level)
        // console.log("sreid: ", sreid)
        // console.log(p.target)
        // console.log("target level: ", level)
        self.stimulate('Skill#adapt_target_rating', sreid, level).then(() => {
          p.targetEndpoint.setType("target")
        })
      }) 

    })
  }

}
