import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["label", "toggle"];


  connect() {
    super.connect()
    const self = this
    if(this.hasToggleTarget) {
      this.toggleTargets.forEach((target, index, targets) => {
        const showExtendedTooltips = $(target).prop("checked")
        self.showTooltip({}, showExtendedTooltips)
      })
    }
  }

  ///
  // toggles the extended definition tooltip option in the current users profile
  ///
  toggleExtendedDefinitionTooltip(event) {
    let self = this
    let $target = $(event.currentTarget)
    let showExtendedTooltips = $target.prop("checked");
    // console.log("showExtendedTooltips: ", showExtendedTooltips)

    this.stimulate("profileReflex#set_extended_definition_tooltip", !showExtendedTooltips, 'journey_builder').then(payload => {
      self.showTooltip(payload, showExtendedTooltips)
    })
  }

  /**
   * Show tooltip.
   * @param {*} payload
   * @param boolean showExtendTooltips
   */
  showTooltip(payload, showExtendedTooltips) {
    const { data, element, event } = payload
    // console.log("data: ", data)
    // console.log("element: ", element)
    // console.log("event: ", event)
    let tooltipTitle = showExtendedTooltips ? "data-long-title" : "data-short-title"
    let tooltiped = $("#group_definition_area").find(".learn-unit-item, .card.definition-item")
    // console.log("tooltipped: ", tooltiped)
    tooltiped.tooltip("dispose")
    tooltiped.tooltip(
      {
        title: function() {return $(this).attr(tooltipTitle)}
      }
    )
  }

}
