import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // adds a new definition to the current definition group
  selected(event) {
    if($(event.target).closest("a").length > 0 && event.target != event.currentTarget) {
      // do nothing should not interest you!
      console.log($(event.target).closest("a"))
    } else {
      event.stopPropagation()
      event.preventDefault()
      let $currentTarget = $(event.currentTarget)

      if($currentTarget.data('target')) {
        console.log($currentTarget.data('target'))
        $currentTarget = $(`#${$currentTarget.data('target')}`)
      }

      const hasClass = $currentTarget.hasClass("selected")

      $(".card.selected").css({
        "border-color": "",
        "background-color": ""
      })
      $(".card.selected").removeClass("selected")
      if (!hasClass) {
        $currentTarget.addClass("selected")
        const color = $currentTarget.attr("data-color")
        const backgroundColor = $currentTarget.attr("data-background-color")
        if (color) {
          $currentTarget.css({
            "border-color": color,
          })
        }
        if(backgroundColor) {
          $currentTarget.css({
            "background-color": backgroundColor,
          })
        }
      }
    }
  }
}