import { Controller } from '@hotwired/stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  dragstart(event) {
    // console.log('drag started')
    let target = $(event.target);
    copyDataToDataTransfer(event.dataTransfer, target.data());
  }

  dragover(event) {
    // console.log('drag over')
    event.preventDefault();
  }

  dragend(event) {
    let journeyId, userId;
    let item = $(event.currentTarget).data("item");

    switch (item) {
      case "users":
        userId = $(event.currentTarget).data('userid');
        // console.log("user ID: ", userId);
        this.stimulate("GroupsReflex#remove_user", userId);
        break;
      case "journeys":
        journeyId = $(event.currentTarget).data('journeyid');
        this.stimulate("GroupsReflex#remove_journey", journeyId);
        break;
      case "user_journeys":
        journeyId = $(event.currentTarget).data('journeyid');
        this.stimulate("UsersReflex#remove_journey", journeyId);
        break;
      default:
        break;
    }
  }

  drop(event) {
    // console.log("dropped an element")
    let journeyId, userId, groupId;
    let maxDuration = event.dataTransfer.getData('duration');
    let item = $(event.currentTarget).data('item');
    let modal = $("#journey_assignment_modal");
    let modalSubmitButton = modal.find("#assignment_modal_submit_button");
    // console.log("element: ", $(event.currentTarget));
    switch(item) {
      case 'users':
        userId = event.dataTransfer.getData('userid');
        this.stimulate('GroupsReflex#add_user', userId);
        break;
        case 'journeys':
          groupId = event.dataTransfer.getData('groupid');
          journeyId = event.dataTransfer.getData('journeyid');
          console.log(`adding journey '${journeyId}' to group '${groupId}'`);
          // console.log("journeyId: ", journeyId);
          modal.find("input#max_duration").val(maxDuration);
          modalSubmitButton.attr("data-journeyid", journeyId);
          modalSubmitButton.attr("data-groupid", groupId);
          modal.modal("show");
        break;
      case 'user_journeys':
        userId = event.dataTransfer.getData('userid');
        journeyId = event.dataTransfer.getData('journeyid');
        console.log(`adding journey '${journeyId}' to user '${userId}'`);
        modal.find("input#max_duration").val(maxDuration);
        modalSubmitButton.attr("data-journeyid", journeyId);
        modalSubmitButton.attr("data-userid", userId);
        modal.modal("show");
        break;
      default:
        console.log(`no fitting item given: ${item}`)
        break;
    }
  }

  // assigns a journey to an user
  assign(event) {
    let journeyId = $(event.currentTarget).data('journeyid');
    let userId = $(event.currentTarget).data('userid');
    // console.log("journey: ", journeyId);
    // console.log("user: ", userId);
    let modal = $("#journey_assignment_modal");
    let maxDuration = modal.find("input#max_duration").val();
    modal.modal('hide');
    this.stimulate('UsersReflex#assign_journey', userId, journeyId, maxDuration, $('#choose_user_mentor').val());
  }

  // assigns a journey to a group
  assign_to_group(event) {
    let journeyId = $(event.currentTarget).data('journeyid');
    let groupId = $(event.currentTarget).data('groupid');
    // console.log("journey: ", journeyId);
    // console.log("group: ", groupId);
    let modal = $("#journey_assignment_modal");
    let maxDuration = modal.find("input#max_duration").val();
    modal.modal('hide');
    this.stimulate('GroupsReflex#assign_journey', groupId, journeyId, maxDuration);
  }
}

function copyDataToDataTransfer(dataTransfer, data) {
  for (let [key, value] of Object.entries(data)) {
    dataTransfer.setData(key, value);
  }
  return dataTransfer
}