import ApplicationController from './application_controller'
import Sortable, { Swap } from 'sortablejs';

Sortable.mount(new Swap());

export default class extends ApplicationController {

  connect() {
    super.connect();
    let self = this;

    new Sortable(this.element, {
      removeOnSpill: false,
      draggable: ".phase-box:not(.end-phase)",
      filter: ".phase-box.end-phase",
      swap: true,
      swapClass: "highlight",
      animation: 150,
      onSort: function (event) {
        updateOrder();
      },
      // onSpill: function (event) {
      //   let id = $(event.item).data("form-id");
      //   $(`.form-block#form_element_${id}`).remove();
      //   $(`[value=${id}]`).remove();
      //   if ($(".phase-box:not(.end-phase").length > 1) {
      //     $(event.item).next(".add-phase-container").remove();
      //   }
      //   setTimeout(updateOrder, 50);
      //   self.removePhase(id);
      // },
    });

  }

  ///
  // adds a phase and visually a phase element 
  ///
  addPhase(event) {
    let container = $(this.element)
    let newPhase = document.createElement("div")
    let $journeyForm = $("#journey_form")
    $(newPhase).attr("id", "new_phase_element")
    $(newPhase).addClass('phase-box')

    console.log($(event.currentTarget).closest('.add-phase-container'))

    // this.logInfo(`container classes: ${container.children().first().attr('class')}`)

    $(newPhase).insertAfter(
      $(event.currentTarget).closest(".add-phase-container")
    );

    const index = $(newPhase).index('.phase-box')

    this.logInfo(`index: ${index}`)

    // const position = Math.max(0, (index / 2))

    $journeyForm.find("div.form-block").addClass('hidden')

    // this.logInfo(`position: ${position}`)

    this.stimulate(
      "PhaseBuilderReflex#add_phase",
      $(this.element).data("journey-id"),
      index + 1
    )
  }

  ///
  // adds the proposed phase as a phase to the journey
  ///
  addProposedPhase(event) {
    const $target = $(event.currentTarget)
    event.preventDefault()
    const journeyId = $target.closest('.phase-line-container').data('journey-id')
    this.stimulate('Journeys#add_proposed_phase', event.currentTarget, journeyId).then((event) => {
      // $("#journey_structure_modal .modal-footer").text(`Wir suchen nach '${$input.val()}'`)
    });

  }


  removePhase(event) {
    this.stimulate('PhaseBuilderReflex#remove_phase', id)
  }

  showSkillSet(event) {
    const self = this
    event.preventDefault();
    event.stopPropagation();
    const $target = $(event.currentTarget);
    this.startLoading()
    this.stimulate('PhaseBuilderReflex#show_skill_set', event.currentTarget).then(payload => {
      self.stopLoading()
      $("#skill_set_modal").modal("show")      
    });

  }

  skillRemoved(event) {
    this.stimulate('PhaseBuilderReflex#remove_skill_from_skill_set').then(payload => {
    });
  }

  showJourneySkillSet(event) {
    const self = this
    event.preventDefault();
    event.stopPropagation();
    const $target = $(event.currentTarget);
    this.startLoading()
    this.stimulate('PhaseBuilderReflex#show_journey_skill_set').then(payload => {
      // const { data, element, event } = payload;
      // console.log("element: ", element)
      self.stopLoading()
      $("#skill_set_modal").modal("show")
    });

  }

}

function updateOrder() {
  let phases = $('.phase-box[data-form-id]')
  let order = []
  for (let phase of phases) {
    let id = $(phase).data("form-id")
    order.push(id)
  }
  $('#journey_order').val(order.join(' '))
}
