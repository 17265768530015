import ApplicationController from './application_controller'


export default class extends ApplicationController {

  initialize() {
    super.initialize()
    $('[data-bs-toggle="tooltip"]').tooltip({ boundary: "window" });

  }

}