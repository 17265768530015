import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["datetimestring", "timestring", "datestring"]

  static values = {
    locale: String,
    defaultlocale: String,
    environment: String
  }

  initialize() {
    super.initialize();
    // initialize I18n-js
    this.useReflex = false;
    this.initializeI18n();

    // Date/time initialization
    window.datetime = {
      format: I18n.t('javascript.format.both'),
      formatTime: I18n.t('javascript.format.time'),
      formatDate: I18n.t('javascript.format.date'),
      toLocal: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.format)
      },
      toLocalDate: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.formatDate)
      },
      toLocalTime: function(date) {
        return moment.utc(date, moment.iso8601, true).local().format(this.formatTime)
      },
      toUTC: function(date) {
        return moment(date, this.format, true).utc().format(moment.iso8601)
      }
    }
    
    this.setupTooltips();
  }

  // here all the things are initialized (after page load), which are needed on every page
  connect() {
    super.connect();
    // add autoclose for notice alerts
    const autoCloseAlert = function(time) {
      setTimeout(function(){
        $('.alert.alert-primary').fadeOut('slow');
      }, time);
    }
  
    autoCloseAlert(2500); // Close after 2,5 seconds


    // initialize maps
    // if (!window.mapInitializer) {
    //   window.mapInitializer = {
    //     needs_initialization: true
    //   };
    // }

    // if (window.mapInitializer.needs_initialization) {
    //   let resource = document.createElement('script');
    //   resource.type = 'text/javascript';
    //   resource.async = true;
    //   resource.crossorigin = 'Anonymous';
    //   resource.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCFcHcoyyBtOcfEaWIxRCCzBi9XTiAMQGM&libraries=places&callback=initializeGoogleMaps';
    //   let script = document.getElementsByTagName('script')[0];
    //   script.parentNode.insertBefore(resource, script);
    //   window.mapInitializer.needs_initialization = false;
    // }    
  }

  datetimestringTargetConnected(el) {
    $(el).text(datetime.toLocal($(el).attr("data-value")))
  }

  datestringTargetConnected(el) {
    $(el).text(datetime.toLocalDate($(el).attr("data-value")))
  }

  timestringTargetConnected(el) {
    $(el).text(datetime.toLocalTime($(el).attr("data-value")))
  }

  initializeI18n() {
    I18n.defaultLocale = this.defaultlocaleValue
    I18n.locale = this.localeValue
    let locale = I18nLocales[this.localeValue]
    I18n.store(locale)
  }

}
