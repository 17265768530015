import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["button"]

  static values = {
    init: String,
    manipulationmethod: String
  }

  initialize() {
    super.initialize();
    this.functionMap = {
      "renderSeminarSummary": (event) => {
        this.renderSeminarSummary(event);
      },
      "useData": (event) => {
        this.useData(event);
      },
      "onCheck": (event) => {
        this.onCheck.bind(this)(event);
      }
    }

    this.initializers = {
      external: this.initializeExternal,
      radio_button: this.initializeRadioButton
    }

    this.hasDate = false
    this.agreementsChecked = false
  }

  connect() {
    let self = this;
    this.$area = $(this.element)
    let initMethods = this.initValue.split(" ");
    initMethods.forEach(function (initializer, index) {
      self.initializers[initializer].bind(self)()
    })
    super.connect();
  }

  initializeExternal() {
    console.log("initializes External")
  }

  initializeRadioButton() {
    let self = this;
    // check for date checks
    if(this.$area.find("input[type=radio]").length == 0) {
      this.hasDate = true
    } else {
      this.$area.on('change', 'input[type=radio]', function (event) {
        self.functionMap[self.manipulationmethodValue](event);
      });
    }
    // console.log(`we have a date: ${this.hasDate}`)

    // persist the forms action
    const $button = $(this.buttonTarget)
    const $form = $button.closest("form");
    this.formAction = $form.attr("action")

    // also check if there is at least one agreement and enable send button else
    this.agreementChecks = this.$area.find("#agreement_area input[type=checkbox]").toArray()
    if(this.hasDate && this.agreementChecks.length == 0) {
      $button.attr('disabled', false)
    }


    // console.log("initialized RadioButton")
  }

  ///
  // shows the following tab
  ///
  next(event) {
    event.preventDefault();
    let self = this;
    let $target = $(event.currentTarget);
    // let $currentTab = $target.closest(".tab-pane");
    // console.log("current tab: ", $currentTab)
    // let $nextTab = $currentTab.next('.tab-pane')
    // console.log("next tab: ", $nextTab)
    // $nextTab.tab('show');
    let $currentTab = $("#tab_navigation .nav-link.active");
    // console.log("current tab: ", $currentTab)
    let $nextTab = $currentTab.closest('.nav-item').next('.nav-item ').find('a')
    $nextTab.removeClass('disabled');
    // console.log("next tab: ", $nextTab)
    /// check if we have an extra action that must be executed before the new tab is shown
    let extraAction = $target.attr('data-extra-action');
    if(extraAction) {
      $nextTab.on('shown.bs.tab', function (event) {
        self.functionMap[extraAction](event);
      });
    }
    // console.log("extra action: ", extraAction)
    $nextTab.trigger('click');
    // $nextTab.tab('show');
  }  
  
  ///
  // shows the prevous tab
  ///
  back(event) {
    event.preventDefault();
    let $target = $(event.currentTarget);
    let $currentTab = $("#tab_navigation .nav-link.active");
    // console.log("current tab: ", $currentTab)
    let $prevTab = $currentTab.closest('.nav-item').prev('.nav-item ').find('a')
    if($prevTab.size == 0) {
      return
    }
    // console.log("previous tab: ", $prevTab)
    // $prevTab.tab('show');
    $prevTab.trigger('click');
  }

  ///
  // copies the profile entries of the requester to the participant area
  ///
  participateYourself(event) {
    event.preventDefault();
    let $target = $(event.currentTarget);
    // find form part
    let participantDataArea = $target.closest('.tab-pane').find('.participants');
    let goOn = true;
    /// if checkbox checked
    if($target.prop("checked")) {
      let email = participantDataArea.find('input#external_booking_email').val();
      // console.log("email: ", email)

      let firstName = participantDataArea.find('input#external_booking_first_name').val();
      let lastName = participantDataArea.find('input#external_booking_last_name').val();
      goOn = !(email == "") && !(firstName == "") && !(lastName == "")
      if(goOn) {
        // let fullName = `${firstName} ${lastName}`;
        // console.log("name: ", fullName)
        let $template = $("template#participant_template")
        let $participant = $($template[0].content.cloneNode(true))
        $participant.find('input#external_booking_participants_email').val(email).attr('readonly', true).attr('id', null)
        $participant.find('input#external_booking_participants_first_name').val(firstName).attr('readonly', true).attr('id', null)
        $participant.find('input#external_booking_participants_last_name').val(lastName).attr('readonly', true).attr('id', null)
        $participant.find('.delete-button').remove()
        // console.log("participant: ", $participant)
        $("#participants_list").prepend($participant)
      } else {
        console.log('data missing!!!')
        $target.prop( "checked", !$target.prop("checked") )
        return null
      }
    } else { /// unchecked
      $("#participants_list").children("li").first().detach()
    }
  }

  ///
  // adds a participant form row
  ///
  addParticipant(event) {
    event.preventDefault();
    let $target = $(event.currentTarget);
    // find form part
    let $participantsList = $("#participants_list")
    let $template = $("template#participant_template")
    let $participant = $($template[0].content.cloneNode(true))
    let number = $participant.find(".number")
    number.text($participantsList.children("div").length + 1)
    $participant.find('input#external_booking_participants_email').attr('id', null)
    $participant.find('input#external_booking_participants_first_name').attr('id', null)
    $participant.find('input#external_booking_participants_last_name').attr('id', null)
    // console.log("participant: ", $participant)
    $participantsList.append($participant)
  }

  ///
  // deletes participant from list
  ///
  deleteParticipant(event) {
    event.preventDefault();
    let $target = $(event.currentTarget);
    // find form part
    let $participant = $target.closest("li")
    $participant.detach()
  }

  ///
  // renders the summary for the exeternal booking 
  ///
  renderSeminarSummary(event) {
    let $target = $(event.target);
    // console.log("we render the seminar summary")
    let $seminarArea = $("#finish_seminar_area")
    $seminarArea.html('')
    let $participantsArea = $("#finish_participants_area")
    $participantsArea.html('')
    let $invoiceFinishArea = $("#finish_invoice_area")

    // 1) find modules part and copy them
    let $eventsDataArea = $('#modules');
    $( "input.boolean:checked", $eventsDataArea ).each(function( index ) {
      let $input = $(this)
      // console.log("we have: ", $input)
      let $label = $input.parent().find('label').clone();
      // console.log("label: ", $label)
      $seminarArea.append($label)
    });

    // 2) find participants part and copy them 
    let $participantDataArea = $('#participants_list');
    let email, firstName, lastName, fullName;
    let participants = [];
    $( "li", $participantDataArea ).each(function( index ) {
      let $li = $(this)
      email = $li.find('.external_booking_participants_email input').val();
      // console.log("email: ", email)
      
      firstName = $li.find('.external_booking_participants_first_name input').val();
      lastName = $li.find('.external_booking_participants_last_name input').val();
      fullName = `${firstName} ${lastName}`;
      participants.push(`${fullName}, ${email}`)
      // console.log("name: ", fullName)
    });
    let participants_html = "<ol>"
    participants.forEach(function(part){
      participants_html += `<li>${part}</li>`
    });
    participants_html += "</ol>"
    $participantsArea.html(participants_html)

    // 3) get the invoice data and represent it
    let $invoiceArea = $("#invoice_area")
    let invoiceEmail = $invoiceArea.find("input#external_booking_invoice_email").val()
    let invoiceFirstName = $invoiceArea.find("input#external_booking_invoice_first_name").val()
    let invoiceLastName = $invoiceArea.find("input#external_booking_invoice_last_name").val()
    let company = $invoiceArea.find("input#external_booking_invoice_company").val()
    let addressSupplement = $invoiceArea.find("input#external_booking_invoice_address_supplement").val()
    let pob = $invoiceArea.find("input#external_booking_invoice_pob").val()
    let invoiceStreet = $invoiceArea.find("input#external_booking_invoice_street").val()
    let invoiceHouseNumber = $invoiceArea.find("input#external_booking_invoice_house_number").val()
    let zip = $invoiceArea.find("input#external_booking_invoice_zip").val()
    let city = $invoiceArea.find("input#external_booking_invoice_city").val()
    let vat = $invoiceArea.find("input#external_booking_invoice_ustid").val()
    let phone = $invoiceArea.find("input#external_booking_invoice_telephone_number").val()
    
    let divider = ", "
    let address = `${company}${divider}${invoiceFirstName} ${invoiceLastName}${divider}`
    if (addressSupplement) {
      address += `${addressSupplement}${divider}` 
    }
    if (pob) {
      address += pob
    } else {
      address += `${invoiceStreet} ${invoiceHouseNumber}`
    }
    address = `${address}${divider}${zip} ${city}`

    $invoiceFinishArea.find('.email .replacement').html(invoiceEmail)
    $invoiceFinishArea.find('.address .replacement').html(address)
    if(vat) {
      $invoiceFinishArea.find('.vat .replacement').html(vat)
    }
    if(phone) {
      $invoiceFinishArea.find('.phone .replacement').html(phone)
    }


    // 4) at least also check, if we can enable the send button
    this.checkValidity()
  }

  ///
  // takes part of the bookers data and enter it into invoice data 
  ///
  useData(event) {
    let $target = $(event.target);
    console.log("we use preentered data")
    // 1) get the bookers needed data
    let $bookerArea = $("#booker_area")
    let email = $bookerArea.find("input#external_booking_email").val()
    console.log(`email: ${email}`)
    let firstName = $bookerArea.find("input#external_booking_first_name").val()
    let lastName = $bookerArea.find("input#external_booking_last_name").val()

    // 2) enter data in invoice area
    let $invoiceArea = $("#invoice_area")
    $invoiceArea.find("input#external_booking_invoice_email").val(email)
    $invoiceArea.find("input#external_booking_invoice_first_name").val(firstName)
    $invoiceArea.find("input#external_booking_invoice_last_name").val(lastName)
    // 3) at least also check, if we can enable the send button
    // this.checkValidity()
  }

  ///
  // checks if the acceptance checkboxes are checked and if at least one event and one participant is selected
  ///
  checkValidity(event) {
    let $sendButton = $('#send_external_booking')
    let $agreementCheck = $("#finish_agreement_area input.boolean").toArray()
    // console.log("agreement check: ", $agreementCheck)

    let hasEvent, hasParticipants = false;
    hasEvent = $("#finish_seminar_area").find('label').length > 0
    hasParticipants = $("#finish_participants_area ol li").length > 0

    if (hasEvent && hasParticipants) {
      /// also check the agreements rules, if given
      if($agreementCheck.length > 0) {
        $sendButton.attr('disabled', !$agreementCheck.every((agreement) => $(agreement).prop("checked")))
      } else {
        $sendButton.attr('disabled', false)
      }
    } else {
      $sendButton.attr('disabled', true)
    }
  }

  send(e) {
    e.preventDefault()
    this.stimulate('ExternalBooking#submit').then(() => {
      // console.log("submitted form")
    })
  }

  ///
  // checks if a date has been chosen and set this.hasDate to true
  // also enables the send button when the agreements have been checked also
  ///
  onCheck(event) {
    const $target = $(event.currentTarget);

    const $button = $(this.buttonTarget)
    const $form = $button.closest("form");
    let action = `${this.formAction}?booked_appointment=${$target.val()}`
    // console.log("action: ", action)
    $form.attr("action", action)
    this.hasDate = true
    if(this.agreementsChecked) {
      $button.attr("disabled", false)
    }
  }

  ///
  // checks if all agreements have been checked
  // also enables the send button when a date has been selected so far
  ///
  checkAgreements() {
    // console.log("agreement check: ", this.agreementChecks)
    this.agreementsChecked = this.agreementChecks.every((agreement) => $(agreement).prop("checked"))
    if(this.hasDate) {
      const $button = $(this.buttonTarget)
      if(this.agreementChecks.length > 0) {
        $button.attr('disabled', !this.agreementsChecked)
      } else {
        $button.attr('disabled', false)
      }
    }
  }

}