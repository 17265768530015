import ApplicationController from './application_controller'


export default class extends ApplicationController {

  delete(event) {
    let $target = $(event.currentTarget);
    let appId = $target.data('app-id');

    this.stimulate('Appointment#delete', appId)
  }

  deleteSuccess(element, reflex, error, reflexId) {
    $(element).remove()
  }

  deleteError(element, reflex, error, reflexId) {
    // console.log("we have an error here: ", error)
    let $el = $(element).find(".pseudo-btn.delete")
    $el.attr("title", I18n.t(error));
    $el.tooltip("show")
}

}