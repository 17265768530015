import ApplicationController from './application_controller'
// import Ajax from '../packs/ajax.js';
import { getMetaValue } from "../packs/global_functions";

export default class extends ApplicationController {

  ///
  // creates a new definition
  ///
  new(event) {
    let $target = $(event.currentTarget);
    let modal = $target.closest(".modal");
    let selectedCard = modal.find(".selected");
    let type = selectedCard.attr("data-type");
    let subtype = selectedCard.attr("data-subtype");
    let group_id = $target.attr("data-group-id");
    this.stimulate("DefinitionReflex#new", type, subtype, group_id).then(() => {
      this.replaceAuthenticityTokenInForm();
      modal.modal("hide");
      this.stopLoading();
    });
  }

  // removes given Definition from UI and stimulates corresponding Reflex to delete teh Definitionfrom db also
  removeDefinition(event) {
    event.preventDefault();
    this.startLoading();
    let $target = $(event.currentTarget);
    let defId = $target.data('def-id');
    let defCard = $("#definition_card_" + defId);
    // console.log("groupId: ", groupId);
    // console.log("groupCard: ", groupCard);
    this.stimulate('DefinitionReflex#remove_definition', defId).then(() => {
      defCard.remove();
      this.stopLoading();
    });
  }

  ///
  // replaces the csrf token in form with the right one in header
  ///
  replaceAuthenticityTokenInForm() {
    let $form = $("form#group_definitions_form");
    let $authTokenField = $("input[name=authenticity_token]", $form).first();
    $authTokenField.val(getMetaValue("csrf-token"));
    console.log("!!! authenticity token replaced")
  }


  // async newSuccess() {
  //   $("#new_definition_modal").modal("hide");
  //   let $newTarget = $('.definition-item.selected'),
  //   prefixId = $newTarget.data('prefix-id'),
  //   id = $newTarget.data('id'),
  //   groupDefId = $newTarget.data('group-id');

  //   if(id && groupDefId) {
  //     let formElement = document.createElement('div');
  //     formElement.id = `${prefixId}${id}`;
  //     $(formElement).addClass('form-element');

  //     let groupForm = $('#group_definitions_form');
  //     let objectName = groupForm.find(`#group_object_name_${groupDefId}`).data('object-name');

  //     $(formElement).appendTo(groupForm);
  //     let form = await new Ajax('/definition_form').doAjax("GET", { "id": id, "object_name": objectName });
  //     $(form).appendTo(formElement);
  //   }
  // }
}
