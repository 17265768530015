import ApplicationController from './application_controller'

export default class extends ApplicationController {

  ///
  // switch between group and users view on supervising view
  ///
  switch(event) {
    this.stimulate(
      "SupervisingReflex#switch",
      $(event.currentTarget).prop("checked")
    );
  }

  ///
  // restarts the journey for the user, defined in triggering area
  ///
  restartPersonalJourney(event) {
    console.log("restart personal journey...")
    const $target = $(event.currentTarget)

    const userId = $target.data("user-id")
    const journeyId = $target.data("journey-id")
    this.stimulate('SupervisingReflex#restart_personal_journey', userId, journeyId).then(() => {
      $("#assignment_cards").trigger("chart:changed", `canvas_performance_${userId}`)
    })
  }

  ///
  // stops the journey for the user
  ///
  stopPersonalJourney(event) {
    console.log("stop personal journey...")
    const $target = $(event.currentTarget)

    const userId = $target.data("user-id")
    const journeyId = $target.data("journey-id")
    this.stimulate('SupervisingReflex#stop_personal_journey', userId, journeyId).then(() => {
      $("#assignment_cards").trigger("chart:changed", `canvas_performance_${userId}`)
    })
  }

  ///
  // opens the user card for given user id
  ///
  openCard(event) {
    event.preventDefault();
    console.log("restart personal journey...")
    const $target = $(event.currentTarget)
    
    const userId = $target.data("user-id")
    this.stimulate('SupervisingReflex#show_user_card', userId).then(() => {
      console.log(`user card for ${userId} should be shown now`)
      $("#assignment_cards").trigger("chart:changed", `canvas_performance_${userId}`)
      $("#user_card_modal").modal("show")
    })
  }

  ///
  // opens the user interactions modal
  ///
  showInteractions(event) {
    event.preventDefault();
    console.log("showInteractions...")
    const $target = $(event.currentTarget)
    const interactionElements = $target.data("interaction-elements")
    // hide tooltip of clicked button, else it would still be shown over modal 
    $target.tooltip("dispose");

    this.stimulate('SupervisingReflex#show_interactions', interactionElements).then(() => {
      console.log(`the user interactions (${interactionElements}) should be shown now`)
      $('#interaction_modal [data-bs-toggle="tooltip"]').tooltip({ boundary: "window" });

      $("#interaction_modal").modal("show")
    })

  }

}