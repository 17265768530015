export { application }

import "@stimulus/polyfills";
import { Application } from "@hotwired/stimulus";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import "./share.js";
import "./global_functions";
import VideoPlayer from './video_player.js';
import "chartkick/chart.js";

import './jquery-ui.min.js';
import './jquery-tooltipster';
import './nested_sortable.js'

const application = Application.start()
application.debug = true
window.Stimulus   = application

StimulusReflex.initialize(application, { debug: true });
window.VideoPlayer = VideoPlayer;
VideoPlayer.init();

// Rails.start();

$(function () {
  $('.alert-close').on('click', function(e) {
    e.stopPropagation();
    let parent = $(this).parent('.alert');
    parent.hide();
  });
});

//// Setup Chartkick 
require("chartkick")
// require("chart.js")
import Chartkick from 'chartkick'
// Chartkick.options = {
//   colors: ['#0074d9', '#7fdbff', '#39cccc', '#3d9970', '#01ff70', '#85144b', '#f012be', '#b10dc9']
// }
window.Chartkick = Chartkick

////
// I18N
////
import { I18n } from "i18n-js";

import de from "../js_locales/de.json"
import en from "../js_locales/en.json"

const i18n = new I18n();
window.I18n = i18n;
window.I18nLocales = {
  de: de,
  en: en
}
